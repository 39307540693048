<template>
  <PersonList
    :people="props.people"
    :is-draggable="true"
    :draggable-change="movePersonToBuilding"
    draggable-group="people"
    draggable-class="people-drag-area"
    class="people-drag-area"
  />
</template>

<script setup>
import PersonList from '@/components/_partials/Organism/PersonList.vue'
import { useBuildingStore } from '@/stores/BuildingStore'
import { updatePerson } from '@/js/utils/unitAssignmentUtil'
import { watch } from 'vue'

const props = defineProps({
  people: {
    type: Array,
    default: () => []
  }
})

watch(() => props.people, (newPeople) => {
}, { deep: true })

const buildingStore = useBuildingStore()

function movePersonToBuilding (event) {
  if (event.added) {
    const { unit_id: unitId, ...person } = {
      ...event.added.element.dto,
      building_id: buildingStore.getBuildingId
    }

    if (unitId) {
      const unitIndex = buildingStore.buildingData.units.findIndex(unit => unit.id === unitId)
      if (unitIndex !== -1) {
        buildingStore.buildingData.units[unitIndex].people =
            buildingStore.buildingData.units[unitIndex].people.filter(p => p.id !== person.id)
      }
    }

    updatePerson(person, buildingStore)
  }
}
</script>

<style scoped>
.people-drag-area {
  border: 1px solid #ddd;
  min-height: 50px;
}
</style>
