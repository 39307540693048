import { defineStore } from 'pinia'
import { request } from '@/js/request'
import { API_V1_BUILDING_GET, API_V1_UNIT_ADD } from 'RouteName'

export const useBuildingStore = defineStore('BuildingStore', {
  state: () => {
    return {
      building: {
        units: []
      }
    }
  },
  getters: {
    buildingData: (state) => state.building,
    units: (state) => state.building.units,
    getBuildingId: (state) => state.building.id
  },
  actions: {
    async fill (buildingId) {
      const response = await request({
        route: API_V1_BUILDING_GET,
        params: {
          id: buildingId
        }
      })

      this.building = response.json
    },
    async addUnit (buildingId) {
      const response = await request({
        method: 'POST',
        route: API_V1_UNIT_ADD,
        params: {
          buildingId
        }
      })

      this.buildingData.units.push(response.json)
    }
  }
})
