<template>
  <EntityCard
    v-if="props.item !== null"
    :multiline="isMultiLine"
    :color="props.item.color"
    :href="props.item.href"
    :new-window="props.newWindow"
    :draggable="draggable"
  >
    <template
      v-if="true"
      #header
    >
      <OpenAccessIcon
        v-if="props.item.openAccessIcon"
        class="right floated EntityCardList-open-access--icon"
        :partner="props.item.openAccessIcon"
      />

      <div
        v-if="props.iconsPosition === 'top'"
        class="EntityCardList-icon--container top"
      >
        <FomanticIcon
          v-for="(icon, iconIndex) in props.item.icons"
          :key="icon.name"
          v-bind="{...icon, type: 'EntityCardList-top--icon ' + icon.type}"
          :class="{'multi-icon': iconIndex !== 0}"
        />
      </div>

      <div class="EntityCardList-header">
        <span
          ref="labelElementRef"
          :title="props.item.label && props.item.label.length > 35 ? props.item.label : null"
          class="EntityCardList-label EntityCardList-text ui medium text"
        >
          {{ props.item.label }}
        </span>
      </div>
      <div class="EntityCardList-info EntityCardList-text ui small text">
        {{ props.item.info }}
        <span
          v-if="props.item.notice"
          class="ui text small red"
        >
          {{ props.item.notice }}
        </span>
      </div>
      <div
        v-if="props.iconsPosition === 'bottom'"
        class="EntityCardList-icon--container bottom"
      >
        <FomanticIcon
          v-for="(icon, iconIndex) in props.item.icons"
          :key="icon.name"
          v-bind="{...icon, type: 'EntityCardList-bottom--icon ' + icon.type}"
          :class="{'multi-icon': iconIndex !== 0}"
        />
      </div>
    </template>
  </EntityCard>
  <EntityCard
    v-else
    :small="props.small"
    placeholder
  />
</template>
<script setup>
import OpenAccessIcon from '@/stories/atom/OpenAccessIcon.vue'
import FomanticIcon from '@/components/_partials/Atom/FomanticIcon.vue'
import EntityCard from '@/components/_partials/Molecule/EntityCard.vue'
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue'

const props = defineProps({
  newWindow: {
    type: Boolean,
    default: false
  },
  iconsPosition: {
    type: String,
    default: 'top'
  },
  item: {
    type: Object,
    default: null
  },
  small: {
    type: Boolean,
    default: false
  },
  draggable: {
    type: Boolean,
    default: false
  }
})

const isMultiLine = ref(false)
const labelText = ref(null)
const labelElementRef = ref(null)

const checkTextLines = () => {
  nextTick(() => {
    isMultiLine.value = labelText.value && labelText.value.clientHeight > 16
  })
}

if (props.item !== null) {
  watch(() => props.item.label, () => {
    nextTick(() => {
      labelText.value = labelElementRef.value
      checkTextLines()
    })
  }, { deep: true })
}

onMounted(() => {
  labelText.value = labelElementRef.value
  checkTextLines()

  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.target === labelElementRef.value) {
        isMultiLine.value = entry.contentRect.height > 16
      }
    }
  })

  if (labelElementRef.value) {
    resizeObserver.observe(labelElementRef.value)
  }

  onUnmounted(() => {
    if (labelElementRef.value) {
      resizeObserver.unobserve(labelElementRef.value)
    }
    resizeObserver.disconnect()
  })
})
</script>
