<template>
  <div class="EntityCardList">
    <draggable
      class="ui cards c-cards-small"
      :list="items"
      :group="props.draggableGroup"
      item-key="id"
      :delay="1000"
      :delay-on-touch-only="true"
      @change="props.draggableChange"
    >
      <template #footer>
        <slot
          v-if="items.length === 0"
          name="empty"
        />
      </template>
      <template #item="{element: item}">
        <EntityCardListEntry
          :new-window="props.newWindow"
          :icons-position="props.iconsPosition"
          :small="props.small"
          :draggable="true"
          :item="{ ...item, ...{ href: null } }"
        />
      </template>
    </draggable>
  </div>
</template>
<script setup>
import draggable from 'vuedraggable'
import EntityCardListEntry from '@/components/_partials/Molecule/EntityCardListEntry.vue'

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  newWindow: {
    type: Boolean,
    default: false
  },
  small: {
    type: Boolean,
    default: false
  },
  iconsPosition: {
    type: String,
    default: 'top'
  },
  items: {
    type: Array,
    default: () => []
  },
  draggableChange: {
    type: Function,
    required: true
  },
  draggableGroup: {
    type: String,
    required: true
  }
})
</script>
