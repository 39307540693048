<template>
  <NoticePanel v-if="devEnvironment">
    <div class="ui grid equal width">
      <div class="column">
        <b>Entwicklungsversion</b><br>Auf diesem Server befinden sich Funktionen, die gerade in Entwicklung sind.
        Manche Funktionen (z.B. E-Mail-Versand) sind auf diesem Server deaktiviert.
      </div>
      <div class="column c-flex-min-content c-flex-align-center">
        <Checkbox
          v-model="checked"
          label="Ich weiß"
        />
      </div>
    </div>
  </NoticePanel>
</template>
<script setup>
import NoticePanel from '@/stories/molecule/NoticePanel.vue'
import { Checkbox } from 'vue-fomantic-ui'
import { ref, watch } from 'vue'

const checked = ref(false)

defineProps({
  devEnvironment: {
    type: Boolean,
    default: false
  }
})

watch(checked, (value) => {
  document.body.dataset.devHeader = !value
})
</script>
