<template>
  <div class="AvatarEditor">
    <div
      class="AvatarEditor-overlay"
      @click="show = true"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <UserIndicator
        icon
        :name="name"
      />
      <SuiDimmer
        :active="hover"
      >
        <SuiHeader
          as="span"
          icon
          inverted
        >
          <SuiIcon name="pencil alternate" />
        </SuiHeader>
      </SuiDimmer>
    </div>

    <SuiModal v-model="show">
      <SuiModalHeader>Avatar ändern</SuiModalHeader>
      <SuiModalContent
        class="AvatarEditor-modal-content"
      >
        <SuiModalDescription>
          <div :class="{ui: true, form: true, loading, error: error !== null}">
            <Message
              v-if="error !== null"
              negative
              header="Fehler"
              :content="error.message"
            />
            <div class="AvatarEditor-image">
              <UserIndicator
                v-if="file === null"
                icon
                :name="name"
              />
              <ImageCropper
                format="jpeg"
                @error="(e) => onError(e)"
                @changed="(e) => onChanged(e)"
                @result="(e) => onResult(e)"
              >
                <template #buttons>
                  <SuiButton
                    v-if="existing"
                    negative
                    icon="trash"
                    @click="(e) => onRemove(e)"
                  >
                    Bild entfernen
                  </SuiButton>
                </template>
              </ImageCropper>
            </div>
          </div>
        </SuiModalDescription>
      </SuiModalContent>
      <SuiModalActions>
        <SuiButton
          basic
          color="grey"
          class="left floated"
          @click="() => abort()"
        >
          Abbrechen
        </SuiButton>
        <SuiButton
          color="green"
          :loading="loading"
          :disabled="loading || !save"
          @click="() => onSave()"
        >
          <SuiIcon name="save" />
          Speichern
        </SuiButton>
      </SuiModalActions>
    </SuiModal>
  </div>
</template>

<script>
import { Message, SuiDimmer, SuiIcon, SuiModal, SuiModalHeader, SuiHeader, SuiButton, SuiModalActions, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import UserIndicator from '@/components/_partials/Atom/UserIndicator.vue'
import ImageCropper from '@/components/_partials/Molecule/ImageCropper.vue'
import { request } from '@/js/request.js'
import { API_V1_AVATAR_GET, API_V1_AVATAR_DELETE, API_V1_AVATAR_PUT } from 'RouteName'

export default {
  components: {
    ImageCropper,
    UserIndicator,
    SuiDimmer,
    SuiIcon,
    SuiModal,
    SuiModalHeader,
    SuiHeader,
    SuiButton,
    SuiModalActions,
    SuiModalContent,
    SuiModalDescription,
    Message
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    show: false,
    hover: false,
    loading: false,
    error: null,
    save: false,
    changed: false,
    file: null,
    existing: false
  }),
  async mounted () {
    request({
      route: API_V1_AVATAR_GET,
      params: {
        username: this.name
      }
    }).then(({ statusCode }) => {
      this.existing = statusCode === 200
    }).catch(() => {
      this.existing = true
    })
  },
  methods: {
    onChanged (file) {
      this.changed = true
      this.error = null
      this.file = file
    },
    onError (error) {
      this.error = error
    },
    onResult (file) {
      this.save = true
      this.file = file
    },
    abort () {
      this.loading = false
      this.error = null
      this.show = false
    },
    removable () {
      return this.file !== null && this.changed
    },
    async onRemove () {
      this.loading = true

      return request({
        method: 'DELETE',
        route: API_V1_AVATAR_DELETE,
        params: {
          username: this.name
        }
      })
        .then(() => {
          this.show = false
          window.location.reload()
        })
        .catch((e) => {
          this.error = e
        })
        .finally(() => {
          this.loading = false
        })
    },
    async onSave () {
      this.loading = true

      return request({
        method: 'PUT',
        route: API_V1_AVATAR_PUT,
        params: {
          username: this.name
        },
        body: this.file
      })
        .then(() => {
          this.show = false
          window.location.reload()
        })
        .catch((e) => {
          this.error = e
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
