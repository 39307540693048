<template>
  <SuiModal
    v-model="show"
    :closable="false"
  >
    <SuiModalHeader icon>
      <SuiIcon :name="icon" />
      {{ title }}
    </SuiModalHeader>
    <SuiModalContent>
      <ErrorMessage
        v-if="error"
        :error="error"
      />
      <div>
        <slot>
          {{ currentQuestion }}
        </slot>
      </div>
    </SuiModalContent>
    <SuiModalActions>
      <SuiButton
        primary
        icon="remove"
        :content="abort"
        :disabled="loading !== null"
        :loading="loading === 'abort'"
        @click="() => onAbort()"
      />
      <SuiButton
        icon="checkmark"
        :color="color"
        :content="confirm"
        :disabled="loading !== null"
        :loading="loading === 'confirm'"
        @click="() => onConfirm()"
      />
    </SuiModalActions>
  </SuiModal>
</template>
<script setup>
import { ref } from 'vue'
import {
  SuiIcon,
  SuiModal,
  SuiModalActions,
  SuiButton,
  SuiModalContent,
  SuiModalHeader
} from 'vue-fomantic-ui'
import ErrorMessage from '@/components/_partials/Atom/ErrorMessage.vue'

const props = defineProps({
  icon: {
    type: String,
    required: false,
    default: 'exclamation'
  },
  title: {
    type: String,
    required: false,
    default: 'Bestätigen'
  },
  question: {
    type: String,
    required: false,
    default: 'Die Aktion wirklich ausführen?'
  },
  abort: {
    type: String,
    required: false,
    default: 'Abbrechen'
  },
  confirm: {
    type: String,
    required: true,
    default: 'Ja'
  },
  color: {
    type: String,
    required: false,
    default: 'red'
  }
})

const show = ref(false)
const currentQuestion = ref(props.question)
const loading = ref(null)
const error = ref(null)

let currentConfirm

async function onAbort () {
  loading.value = 'abort'
  error.value = null
  await currentConfirm.onAbort()
  loading.value = null
  show.value = false
}

async function onConfirm () {
  loading.value = 'confirm'
  error.value = await currentConfirm.onConfirm()
  loading.value = null
  if (error.value === null) {
    show.value = false
  }
}

const isThenable = (result) => {
  return typeof result === 'object' && result !== null && 'then' in result
}

class Confirm {
  _resolve = null
  _reject = null
  _promise = null
  _promiseResolve = null
  _promiseReject = null
  constructor () {
    const self = this
    this._promise = new Promise((resolve, reject) => {
      self._promiseResolve = resolve
      self._promiseReject = reject
    })

    this._promise.then(() => {}).catch(() => {})
  }

  get resolve () {
    return this._resolve
  }

  get reject () {
    return this._reject
  }

  get promise () {
    return this._promise
  }

  async _await (result) {
    if (result instanceof Function) {
      result = result()
    }

    if (isThenable(result)) {
      result = await result
    }

    return result
  }

  then (callback) {
    if (this._resolve !== null) {
      return this._promise
    }
    this._resolve = callback
    return this
  }

  catch (callback) {
    if (this._reject !== null) {
      return this._promise
    }
    this._reject = callback
    return this
  }

  finally (callback) {
    return this._promise.finally(callback)
  }

  async onAbort () {
    await this._promiseReject(await this._await(this._reject))
  }

  async onConfirm () {
    const errorHandled = this._reject !== null
    try {
      await this._promiseResolve(await this._await(this._resolve))
      return null
    } catch (e) {
      if (errorHandled) {
        await this._await(this._reject(e))
      }
      this._promiseReject(e)
      if (!errorHandled) {
        return e
      }
    }

    return null
  }
}

const ask = (question, doResolve) => {
  if (question instanceof Function) {
    doResolve = question
    question = undefined
  }

  currentQuestion.value = props.question
  if (typeof question !== 'undefined') {
    currentQuestion.value = question
  }

  show.value = true
  currentConfirm = new Confirm()

  if (typeof doResolve !== 'undefined') {
    // eslint-disable-next-line promise/catch-or-return
    currentConfirm.then(doResolve)
  }

  return currentConfirm
}

defineExpose({
  ask
})
</script>
