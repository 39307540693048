<template>
  <div
    id="dynamicTopicForm"
    ref="root"
    v-set-selection="setSelection"
    :data-id="prefix + 'DynamicTopicForm'"
    @set-selection="setSelection"
  >
    <div class="dynamic-topic-field-group">
      <div class="labeled-dropdown">
        <span class="label">Thema</span>
        <sui-dropdown
          :key="selectedTopic"
          v-model="topicInput"
          :options="topicDropDown"
          placeholder="Kein Thema"
          search
          selection
        />
      </div>
      <button
        v-if="clearable"
        class="clear"
        title="Thema zurücksetzen"
        @click.prevent="selectedTopic = null"
      >
        x
      </button>
      <input
        v-if="selectedTopic !== null"
        type="hidden"
        :value="topics[selectedTopic].id"
        :name="prefix + '[topic]'"
      >
    </div>
    <span
      v-if="subTopicRequired && selectedSubTopic === null"
      class="subTopic_required"
    >Bitte Unterthema wählen!<br></span>
    <div class="dynamic-topic-field-group">
      <div class="labeled-dropdown">
        <span class="label">Unterthema</span>
        <sui-dropdown
          :key="selectedSubTopic"
          v-model="subTopicInput"
          :placeholder="subTopicRequired ? 'Unterthema auswählen' : 'Kein Unterthema'"
          :options="subTopicDropDown"
          :search="selectedTopic !== null && topics[selectedTopic].children.length > 0"
          selection
        />
      </div>
      <button
        v-if="selectedTopic !== null && clearable"
        class="clear"
        title="Unterthema zurücksetzen"
        @click.prevent="selectedSubTopic = null"
      >
        x
      </button>
      <input
        v-if="selectedSubTopic !== null"
        type="hidden"
        :value="selectedSubTopic"
        :name="prefix + '[subtopic]'"
      >
    </div>
  </div>
</template>

<script>
import { request } from '@/js/request'
import { SuiDropdown } from 'vue-fomantic-ui'
import { API_V1_PRIVILEGE_HISTORY_USER_GET } from 'RouteName'

export default {
  components: {
    SuiDropdown
  },
  directives: {
    setSelection: {
      beforeMount (el, binding) {
        el.addEventListener('set-selection', binding.value, false)
      },
      unmounted (el, binding) {
        el.removeEventListener('set-selection', binding.value, false)
      }
    }
  },
  props: {
    userId: { type: Number, default: null },
    prefix: { type: String, default: '' },
    clearable: { type: Boolean, default: false },
    initialTopicId: { type: Number, default: null },
    initialSubtopicId: { type: Number, default: null }
  },
  data () {
    return {
      topics: [],
      selectedTopic: null,
      selectedSubTopic: null
    }
  },
  computed: {
    subTopicRequired () {
      return this.selectedTopic !== null && this.topics[this.selectedTopic].subtheme_required
    },
    topicDropDown () {
      return this.topics.map(function (topic, index) {
        return {
          index,
          value: topic.id,
          text: topic.topic_name,
          disabled: !topic.privilege || topic.readonly
        }
      })
    },
    subTopicDropDown () {
      if (this.selectedTopic === null) {
        return []
      }
      return this.topics[this.selectedTopic].children.map(function (subtopic) {
        return {
          value: subtopic.id,
          text: subtopic.topic_name,
          disabled: !subtopic.privilege || subtopic.readonly
        }
      })
    },
    topicInput: {
      get () {
        return this.selectedTopic === null ? null : this.topics[this.selectedTopic].topic_name
      },
      set ({ value, index }) {
        if (value) {
          this.$emit('topic-changed', value)
        }
        this.selectedTopic = index
        this.selectedSubTopic = null // unselect subtopic when a new topic is selected
      }
    },
    subTopicInput: {
      get () {
        if (this.selectedTopic === null) {
          return null
        }
        const subTopic = this.topics[this.selectedTopic].children.find(i => i.id === this.selectedSubTopic)

        return subTopic === undefined ? null : subTopic.topic_name
      },
      set ({ value }) {
        if (value) {
          this.$emit('subtopic-changed', value)
        }
        this.selectedSubTopic = value
      }
    }
  },
  watch: {
    initialTopicId: function () {
      if (this.initialTopicId === null) {
        this.selectedTopic = null
        this.selectedSubTopic = null
      }

      this.updateSelection(this.initialTopicId, this.initialSubtopicId)
    }
  },
  created () {
    request({
      route: API_V1_PRIVILEGE_HISTORY_USER_GET,
      params: {
        userId: this.userId
      }
    }).then(({ json }) => {
      this.topics = json
      this.updateSelection(this.initialTopicId, this.initialSubtopicId)
    }).catch((e) => {
      this.error = e
    })
  },
  methods: {
    setSelection (event) {
      const detail = event.detail
      this.updateSelection(detail.topicId, detail.subtopicId)

      // Unselect Topic or subTopic if on shortcut selection the IDs is undefined
      if (detail.topicId === undefined) {
        this.selectedTopic = null
      }
      if (detail.subtopicId === undefined) {
        this.selectedSubTopic = null
      }
    },
    updateSelection: function (topicId, subtopicId) {
      if (topicId === null) {
        return
      }

      for (const [topicIndex, topic] of this.topics.entries()) {
        if (topic.id === topicId) {
          this.selectedTopic = topicIndex
          this.selectedSubTopic = subtopicId
          break
        }
      }
    }
  }
}
</script>

<style scoped>
input[readonly],
input[readonly]:hover {
  border: none !important;
  background-color: #eee !important;
  cursor: default !important;
}

.subTopic_required {
  color: #9f0100;
}

button.clear,
button.clear:active {
  background: #fff;
  color: #888;
  box-shadow: none;
  padding: 3px;
  margin-right: 5px;
}

.dynamic-topic-field-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.dynamic-topic-field-group:first-child {
  margin-bottom: 0.8em;
}

.dynamic-topic-field-group .labeled-dropdown {
  flex-grow: 1;
  margin-right: 5px;
}
</style>
