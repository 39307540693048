<template>
  <div class="EntityCardList">
    <CardGroup
      v-if="items.length > 0 || placeholder > 0"
      :class="{'c-cards-small': small}"
    >
      <EntityCardListEntry
        v-for="(item, index) in items"
        v-if="placeholder === 0"
        :key="'loaded_'+index"
        :new-window="props.newWindow"
        :icons-position="props.iconsPosition"
        :small="props.small"
        :item="item"
      />
      <EntityCardListEntry
        v-for="placeholderIndex in Array(props.placeholder)"
        v-else
        :key="'placeholder_'+placeholderIndex"
        :new-window="props.newWindow"
        :icons-position="props.iconsPosition"
        :small="props.small"
      />
    </CardGroup>
    <div v-else>
      <slot name="empty" />
    </div>
  </div>
</template>
<script setup>
import { CardGroup } from 'vue-fomantic-ui'
import EntityCardListEntry from '@/components/_partials/Molecule/EntityCardListEntry.vue'

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  placeholder: {
    type: Number,
    default: 0
  },
  newWindow: {
    type: Boolean,
    default: false
  },
  small: {
    type: Boolean,
    default: false
  },
  iconsPosition: {
    type: String,
    default: 'top'
  },
  items: {
    type: Array,
    default: () => []
  }
})
</script>
