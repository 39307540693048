import ApiOperation from '@/js/metadata/ApiOperation.js'
export const ErrorOperationErrors = new ApiOperation(JSON.parse("{\"route\":\"_api_errors\",\"method\":\"GET\",\"apiResource\":\"Error\"}"));
export const ConstraintViolationOperationValidationErrorsProblem = new ApiOperation(JSON.parse("{\"route\":\"_api_validation_errors_problem\",\"method\":\"GET\",\"apiResource\":\"ConstraintViolation\"}"));
export const ConstraintViolationOperationValidationErrorsHydra = new ApiOperation(JSON.parse("{\"route\":\"_api_validation_errors_hydra\",\"method\":\"GET\",\"apiResource\":\"ConstraintViolation\"}"));
export const ConstraintViolationOperationValidationErrorsJsonapi = new ApiOperation(JSON.parse("{\"route\":\"_api_validation_errors_jsonapi\",\"method\":\"GET\",\"apiResource\":\"ConstraintViolation\"}"));
export const ProjectTaskOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/project_tasks\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"ProjectTask\"}"));
export const TaskReceiverOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/task_receivers\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"TaskReceiver\"}"));
export const TaskReceiverOperationGetCollection = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/task_receivers{._format}_get_collection\",\"method\":\"GET\",\"apiResource\":\"TaskReceiver\"}"));
export const TaskReceiverOperationPost = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/task_receivers{._format}_post\",\"method\":\"POST\",\"apiResource\":\"TaskReceiver\"}"));
export const TaskReceiverOperationPatch = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/task_receivers\\\/{id}{._format}_patch\",\"method\":\"PATCH\",\"apiResource\":\"TaskReceiver\"}"));
export const TaskReceiverOperationDelete = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/task_receivers\\\/{id}{._format}_delete\",\"method\":\"DELETE\",\"apiResource\":\"TaskReceiver\"}"));
export const BuildingOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/buildings\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Building\"}"));
export const UnitOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/units\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Unit\"}"));
export const ShortcutOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/shortcuts\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Shortcut\"}"));
export const SubtopicOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/subtopics\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Subtopic\"}"));
export const SubtopicOperationGetCollection = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/subtopics{._format}_get_collection\",\"method\":\"GET\",\"apiResource\":\"Subtopic\"}"));
export const SubtopicOperationPost = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/subtopics{._format}_post\",\"method\":\"POST\",\"apiResource\":\"Subtopic\"}"));
export const SubtopicOperationPatch = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/subtopics\\\/{id}{._format}_patch\",\"method\":\"PATCH\",\"apiResource\":\"Subtopic\"}"));
export const SubtopicOperationDelete = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/subtopics\\\/{id}{._format}_delete\",\"method\":\"DELETE\",\"apiResource\":\"Subtopic\"}"));
export const TopicOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/topics\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Topic\"}"));
export const TopicOperationGetCollection = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/topics{._format}_get_collection\",\"method\":\"GET\",\"apiResource\":\"Topic\"}"));
export const TopicOperationPost = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/topics{._format}_post\",\"method\":\"POST\",\"apiResource\":\"Topic\"}"));
export const TopicOperationPatch = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/topics\\\/{id}{._format}_patch\",\"method\":\"PATCH\",\"apiResource\":\"Topic\"}"));
export const TopicOperationDelete = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/topics\\\/{id}{._format}_delete\",\"method\":\"DELETE\",\"apiResource\":\"Topic\"}"));
export const PersonOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/people\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Person\"}"));
export const OrderOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/orders\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Order\"}"));
export const ProjectOperationClonePost = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/projects\\\/{id}\\\/clone{._format}_post\",\"method\":\"POST\",\"apiResource\":\"Project\"}"));
export const ProjectOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/projects\\\/{order}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Project\"}"));
export const ServiceOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/services\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Service\"}"));
export const GroupOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/groups\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"Group\"}"));
export const UserOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/users\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"User\"}"));
export const TaskRelationInterfaceOperationGet = new ApiOperation(JSON.parse("{\"route\":\"_api_\\\/task_relation_interfaces\\\/{id}{._format}_get\",\"method\":\"GET\",\"apiResource\":\"TaskRelationInterface\"}"));
