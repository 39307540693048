<template>
  <div
    id="topicRights"
    class="box"
  >
    <div v-show="contentDisabled">
      <h2 class="subtitle">
        <span>Themenberechtigungen:</span>
      </h2>
    </div>
    <div class="ui container">
      <div class="list-container">
        <div class="content-list">
          <ul>
            <li class="privileges-list">
              <div :class="disabledClass">
                <dl
                  v-for="topic in topics"
                  :key="topic.id"
                >
                  <dt>
                    <tristate-checkbox
                      :ref="'thema' + topic.id"
                      :disabled="contentDisabled || requests >= 1"
                      :checked="topic.privilege && !topic.readonly"
                      :indeterminate="topic.readonly"
                      @change="changeValue(topic, 'thema')"
                    />
                    <span class="checkbox-label">{{ topic.topic_name }}
                      <span
                        v-if="contentDisabled"
                        class="span-disabled"
                      >
                        <span v-if="topic.privileges && topic.privileges.length > 0">-></span>
                        <span
                          v-for="(privilege, index) in topic.privileges"
                          :key="privilege.id"
                        >
                          <span v-if="privilege.direct_privilege">direkt</span>
                          <span v-if="privilege.group_privilege">Gruppe: {{ privilege.group_name }}</span>
                          <span v-if="index+1 < topic.privileges.length">, </span>
                        </span>
                      </span>
                    </span>
                  </dt>
                  <dd
                    v-for="subtopic in topic.children"
                    :key="subtopic.id"
                  >
                    <div>
                      <tristate-checkbox
                        :ref="'subthema' + subtopic.id"
                        :disabled="contentDisabled || requests >= 1"
                        :checked="subtopic.privilege && !subtopic.readonly"
                        :indeterminate="subtopic.readonly"
                        @change="changeValue(subtopic, 'subthema', topic)"
                      />
                      <span class="checkbox-label">{{ subtopic.topic_name }}
                        <span
                          v-if="contentDisabled"
                          class="span-disabled"
                        >
                          <span v-if="subtopic.privileges.length > 0">-></span>
                          <span
                            v-for="(subPrivilege, index) in subtopic.privileges"
                            :key="subPrivilege.id"
                          >
                            <span v-if="subPrivilege.direct_privilege">direkt</span>
                            <span v-if="subPrivilege.group_privilege">Gruppe: {{ subPrivilege.group_name }}</span>
                            <span v-if="index+1 < subtopic.privileges.length">, </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content-note">
      <div>
        <label>
          <input
            class="big-checkbox"
            type="checkbox"
            disabled
          ></label>
        <span class="span-checkbox">Kein Zugriff</span>
      </div>
      <br>
      <div>
        <label>
          <input
            class="big-checkbox"
            type="checkbox"
            disabled
            :indeterminate.prop="true"
          >
        </label>
        <span class="span-checkbox">Lesen</span>
      </div>
      <br>
      <div>
        <label>
          <input
            class="big-checkbox"
            type="checkbox"
            disabled
            checked
          >
        </label>
        <span class="span-checkbox">Lesen und Schreiben</span>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
import { request } from '@/js/request'
import TristateCheckbox from '@/components/_partials/TristateCheckbox'
import {
  API_V1_PRIVILEGE_HISTORY_ALL,
  API_V1_PRIVILEGE_HISTORY_READWRITE,
  API_V1_PRIVILEGE_HISTORY_REMOVE,
  API_V1_PRIVILEGE_HISTORY_SUBTOPIC_ADD,
  API_V1_PRIVILEGE_HISTORY_TOPIC_ADD,
  API_V1_PRIVILEGE_HISTORY_USER_GET
} from 'RouteName'

export default {
  components: {
    'tristate-checkbox': TristateCheckbox
  },
  props: {
    initClientType: { type: String, default: null },
    initClientId: { type: Number, default: null },
    contentDisabled: { type: Boolean, default: false }
  },
  data () {
    return {
      topics: [],
      disabledClass: '',
      requests: 0
    }
  },
  created () {
    if (this.contentDisabled === true) {
      this.getPrivilegesByUser()
      this.disabledClass = 'content-disabled'
    } else {
      this.getPrivileges()
    }
  },
  methods: {
    changeValue (topic, type, parent) {
      const topicRef = this.$refs[type + topic.id][0]

      if (type === 'thema') {
        this.switchTopicPrivilege(topic, topicRef)
      } else if (type === 'subthema') {
        this.switchSubtopicPrivilege(topicRef, topic, parent)
      }
    },
    switchTopicPrivilege (topic, topicRef) {
      const privilegeId = topic.privilege_id
      switch (topicRef.state) {
        case null:
          this.addTopicReadOnlyPrivilege(topic.id)
          break
        case true:
          this.setPrivilegeToReadWrite(privilegeId, 'topic')
          break
        case false:
          this.removePrivilege(privilegeId, 'topic')
      }

      for (const child of topic.children) {
        const subtopicRef = this.$refs['subthema' + child.id][0]
        const subtopicPrivilegeId = child.privilege_id
        switch (topicRef.state) {
          case null:
            if (subtopicRef.state === false) {
              subtopicRef.state = topicRef.state
              this.addSubtopicReadOnlyPrivilege(child.id)
            }
            break
          case true:
            if (subtopicRef.state === null) {
              this.setPrivilegeToReadWrite(subtopicPrivilegeId, 'subtopic')
            } else if (subtopicRef.state === false) {
              this.addSubtopicReadOnlyPrivilege(child.id)
              this.setPrivilegeToReadWrite(subtopicPrivilegeId, 'subtopic')
            }
            subtopicRef.state = topicRef.state
            break
          case false:
            if (subtopicRef.state === null || subtopicRef.state === true) {
              subtopicRef.state = topicRef.state
              this.removePrivilege(subtopicPrivilegeId, 'subtopic')
            }
        }
      }
    },
    switchSubtopicPrivilege (topicRef, subtopic, parent) {
      const parentRef = this.$refs['thema' + parent.id][0]
      const subtopicPrivilegeId = subtopic.privilege_id
      const parentPrivilegeId = parent.privilege_id

      switch (topicRef.state) {
        case null:
          this.addSubtopicReadOnlyPrivilege(subtopic.id)
          if (parentRef.state === false) {
            this.addTopicReadOnlyPrivilege(parent.id)
            parentRef.state = topicRef.state
          }
          break
        case true:
          this.setPrivilegeToReadWrite(subtopicPrivilegeId, 'subtopic')
          if (parentRef.state === null) {
            this.setPrivilegeToReadWrite(parentPrivilegeId, 'topic')
          } else if (parentRef.state === false) {
            this.addTopicReadOnlyPrivilege(parent.id)
            this.setPrivilegeToReadWrite(parentPrivilegeId, 'topic')
          }
          parentRef.state = topicRef.state
          break
        case false:
          this.removePrivilege(subtopicPrivilegeId, 'subtopic')
      }
    },
    addTopicReadOnlyPrivilege (topicId) {
      this.requests = this.requests + 1
      return request({
        method: 'POST',
        route: API_V1_PRIVILEGE_HISTORY_TOPIC_ADD,
        params: {
          topicID: topicId,
          clientType: this.initClientType,
          clientID: this.initClientId
        }
      }).then(({ json }) => {
        this.requests = this.requests - 1
        const topic = this.topics.find(x => x.id === topicId)
        this.addPrivilege(topic, json)
      })
    },
    addSubtopicReadOnlyPrivilege (subTopicId) {
      this.requests = this.requests + 1
      return request({
        method: 'POST',
        route: API_V1_PRIVILEGE_HISTORY_SUBTOPIC_ADD,
        params: {
          subtopicID: subTopicId,
          clientType: this.initClientType,
          clientID: this.initClientId
        }
      }).then(({ json }) => {
        this.requests = this.requests - 1
        const topic = this.topics.find(x => x.children.find(c => c.id === subTopicId))
        const subtopic = topic.children.find(c => c.id === subTopicId)
        this.addPrivilege(subtopic, json)
      })
    },
    setPrivilegeToReadWrite (id, type) {
      this.requests = this.requests + 1
      return request({
        method: 'POST',
        route: API_V1_PRIVILEGE_HISTORY_READWRITE,
        params: {
          id
        }
      }).then(() => {
        if (type === 'topic') {
          const topic = this.topics.find(x => x.privilege_id === id)
          topic.readonly = false
        } else if (type === 'subtopic') {
          const topic = this.topics.find(x => x.children.find(c => c.privilege_id === id))
          const subtopic = topic.children.find(c => c.privilege_id === id)
          subtopic.readonly = false
        }
        this.requests = this.requests - 1
      })
    },
    removePrivilege (id, type) {
      this.requests = this.requests + 1
      return request({
        method: 'POST',
        route: API_V1_PRIVILEGE_HISTORY_REMOVE,
        params: {
          id
        }
      }).then(() => {
        if (type === 'topic') {
          const topic = this.topics.find(x => x.privilege_id === id)
          this.deletePrivilege(topic)
        } else if (type === 'subtopic') {
          const topic = this.topics.find(x => x.children.find(c => c.privilege_id === id))
          const subtopic = topic.children.find(c => c.privilege_id === id)
          this.deletePrivilege(subtopic)
        }
        this.requests = this.requests - 1
      })
    },
    deletePrivilege (topic) {
      topic.readonly = false
      topic.privilege = false
      delete topic.privilege_id
    },
    addPrivilege (topic, id) {
      topic.privilege_id = id
      topic.privilege = true
      topic.readonly = true
    },
    getPrivileges () {
      request({
        route: API_V1_PRIVILEGE_HISTORY_ALL,
        params: {
          clientType: this.initClientType,
          clientID: this.initClientId
        }
      }).then(({ json }) => {
        this.topics = json
      }).catch((e) => {
        this.error = e
      })
    },
    getPrivilegesByUser () {
      request({
        route: API_V1_PRIVILEGE_HISTORY_USER_GET,
        params: {
          userId: this.initClientId
        }
      }).then(({ json }) => {
        this.topics = json
      }).catch((e) => {
        this.error = e
      })
    }
  }
}
</script>

<style scoped>
.big-checkbox {
  zoom: 1;
  transform: scale(2);
}

.span-checkbox {
  padding-left: 5px;
  color: #888;
}

#topicRights .ui.container {
  background-color: white !important;
  padding: 10px;
  width: 80%;
  float: left;
}

.content-disabled {
  pointer-events: none;
  background: #e5e3e324;
}

.content-list {
  height: 700px;
  width: 100%;
  overflow-y: auto;
  margin-left: -5px;
}

.content-note {
  width: 20%;
  float: right;
  padding: 30px 5px 10px;
}

.list-container {
  width: 100%;
  position: relative;
}

.checkbox-label {
  color: #888;
  font-size: 130%;
}

input[type="checkbox"] {
  width: 14px !important;
}

.privileges-list {
  border: 2px solid #f8f7f3;
}

.span-disabled {
  color: #888888a6;
  font-size: 80%;
}
</style>
