<template>
  <div class="UnitAssignmentApp">
    <ErrorMessage :error="error" />
    <SuiButton
      v-if="hasAddUnitPrivilege"
      name="addUnit"
      primary
      icon="plus"
      @click="confirmAddition"
    >
      WE hinzufügen
    </SuiButton>
    <div class="UnitAssignmentApp-splitarea-container">
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        :opacity="0.7"
        color="#0a0a90"
      />
      <splitpanes
        class="default-theme"
        style="height: 800px"
      >
        <pane :size="26.5">
          <SuiMenu class="centered fluid wrapping UnitAssignmentApp-filter--menu">
            <SuiMenuItem as="div">
              <div class="ui action input">
                <input
                  ref="inputPeopleSearchQuery"
                  v-model="peopleSearchQuery"
                  placeholder="Bewohner durchsuchen..."
                  title="Es kann nach Vorname, Nachname und Firma gesucht werden."
                >
                <SuiButton
                  name="clearPeopleSearchQuery"
                  title="Suchfeld leeren"
                  class="tiny"
                  type="button"
                  icon="times"
                  @click="clearPeopleSearchQuery"
                />
              </div>
            </SuiMenuItem>
            <SuiMenuItem as="div">
              <SuiDropdown
                v-model="peopleInBuildingActiveFilter"
                :options="availablePeopleActiveFilters"
                selection
                fluid
              />
            </SuiMenuItem>
          </SuiMenu>
          <div class="scroll-container">
            <PeopleListComponent :people="filteredPeople" />
          </div>
        </pane>
        <pane>
          <SuiMenu class="centered fluid wrapping UnitAssignmentApp-filter--menu">
            <SuiMenuItem as="div">
              <div class="ui action input">
                <input
                  ref="inputUnitsSearchQuery"
                  v-model="unitsSearchQuery"
                  placeholder="Wohnungen durchsuchen..."
                  title="Es kann nach Lage im Gebäude sowie Vorname, Nachname und Firma der Bewohner gesucht werden."
                >
                <SuiButton
                  title="Suchfeld leeren"
                  name="clearUnitsSearchQuery"
                  class="tiny"
                  type="button"
                  icon="times"
                  @click="clearUnitsSearchQuery"
                />
              </div>
            </SuiMenuItem>
            <SuiMenuItem as="div">
              <SuiDropdown
                v-model="unitOccupiedFilter"
                :options="availableUnitOccupiedFilters"
                selection
                fluid
              />
            </SuiMenuItem>
            <SuiMenuItem as="div">
              <SuiDropdown
                v-model="peopleInUnitActiveFilter"
                :options="availablePeopleActiveFilters"
                selection
                fluid
              />
            </SuiMenuItem>
          </SuiMenu>
          <div class="scroll-container">
            <div class="units-container">
              <div
                v-for="unit in filteredUnits"
                :key="unit.id"
              >
                <UnitComponent
                  :unit="unit"
                  :has-delete-unit-privilege="hasDeleteUnitPrivilege"
                  @result="onResult"
                />
              </div>
            </div>
          </div>
        </pane>
      </splitpanes>
    </div>
  </div>
  <ConfirmDialog
    ref="confirm"
    title="Eine neue WE anlegen"
    confirm="Ja, anlegen"
    abort="Nein"
    color="green"
  />
</template>

<script>
import PeopleListComponent from './PeopleListComponent.vue'
import UnitComponent from './UnitComponent.vue'
import { useBuildingStore } from '@/stores/BuildingStore'
import { mapState } from 'pinia'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import { SuiMenu, SuiMenuItem, SuiDropdown, SuiButton } from 'vue-fomantic-ui'
import ErrorMessage from '../_partials/Atom/ErrorMessage.vue'
import ConfirmDialog from '@/components/_partials/Organism/ConfirmDialog.vue'
const availableUnitOccupiedFilters = [
  { value: 'allUnits', text: 'Alle WE' },
  { value: 'occupiedUnits', text: 'Belegte WE' },
  { value: 'emptyUnits', text: 'Leere WE' }
]
const availablePeopleActiveFilters = [
  { value: 'allPeople', text: 'Alle Personen' },
  { value: 'activePeople', text: 'aktive Personen' },
  { value: 'inactivePeople', text: 'inaktive Personen' }
]

const sortPeople = (a, b) => {
  if (a.active !== b.active) {
    return a.active ? -1 : 1
  }

  if (a.nachname !== b.nachname) {
    return a.nachname < b.nachname ? -1 : 1
  }

  if (a.firma !== b.firma) {
    return a.firma < b.firma ? -1 : 1
  }

  return a.vorname < b.vorname ? -1 : 1
}

export default {
  components: {
    ConfirmDialog,
    ErrorMessage,
    SuiMenu,
    SuiMenuItem,
    SuiButton,
    SuiDropdown,
    PeopleListComponent,
    UnitComponent,
    Splitpanes,
    Pane,
    Loading
  },
  props: {
    buildingId: { type: Number, default: null },
    hasAddUnitPrivilege: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasDeleteUnitPrivilege: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  setup (props) {
    const buildingStore = useBuildingStore()
    buildingStore.fill(props.buildingId)

    return {
      buildingStore
    }
  },
  data () {
    return {
      error: null,
      peopleSearchQuery: '',
      unitsSearchQuery: '',
      unitOccupiedFilter: availableUnitOccupiedFilters[0],
      peopleInBuildingActiveFilter: availablePeopleActiveFilters[0],
      peopleInUnitActiveFilter: availablePeopleActiveFilters[0],
      isLoading: true,
      availableUnitOccupiedFilters,
      availablePeopleActiveFilters
    }
  },
  computed: {
    ...mapState(useBuildingStore, {
      buildingData: 'buildingData'
    }),
    filteredPeople () {
      const searchTerms = this.peopleSearchQuery.toLowerCase().split(' ').filter(term => term)

      if (!('people' in this.buildingData)) {
        return []
      }

      return this.buildingData.people.filter(person => {
        if (!this.isPersonActiveFilterMatch(this.peopleInBuildingActiveFilter.value, person)) {
          return false
        }

        if (!this.peopleSearchQuery) {
          return true
        }

        const nachname = person.nachname ? person.nachname.toLowerCase() : ''
        const vorname = person.vorname ? person.vorname.toLowerCase() : ''
        const firma = person.firma ? person.firma.toLowerCase() : ''

        return searchTerms.every(term => nachname.includes(term) ||
            vorname.includes(term) ||
            firma.includes(term)
        )
      }).sort(sortPeople)
    },
    filteredUnits () {
      const searchTerms = this.unitsSearchQuery.toLowerCase().split(' ').filter(term => term)

      return this.buildingData.units.map(unit => {
        const filteredPeople = (unit.people || []).filter(person => {
          return this.isPersonActiveFilterMatch(this.peopleInUnitActiveFilter.value, person)
        }).sort(sortPeople)

        return {
          ...unit,
          filteredPeople
        }
      }).filter(unit => {
        const unitIsOccupied = (unit.people && unit.people.length > 0)

        if ((this.unitOccupiedFilter.value === 'occupiedUnits' && !unitIsOccupied) ||
            (this.unitOccupiedFilter.value === 'emptyUnits' && unitIsOccupied)
        ) {
          return false
        }

        if (!searchTerms || searchTerms.length === 0) {
          return true
        }

        const mapper = person => {
          const nachname = person.nachname ? person.nachname.toLowerCase() : ''
          const vorname = person.vorname ? person.vorname.toLowerCase() : ''
          const firma = person.firma ? person.firma.toLowerCase() : ''

          return vorname + nachname + firma
        }
        const locationInBuilding = unit.location_in_building ? unit.location_in_building.toLowerCase() : ''
        const people = unit.people && unit.people.length > 0 ? unit.people.map(mapper).join(' ') : ''

        return searchTerms.every(term => locationInBuilding.includes(term) || people.includes(term))
      })
    }
  },
  watch: {
    buildingData (newVal) {
      if (newVal) {
        this.isLoading = false
      }
    }
  },
  methods: {
    onResult (message) {
      if (message.error) {
        this.error = message.error
      } else {
        this.error = null
      }
      this.buildingStore.fill(this.buildingId)
    },
    async addUnit () {
      await this.buildingStore.addUnit(this.buildingId)
    },
    clearPeopleSearchQuery () {
      this.peopleSearchQuery = ''
    },
    clearUnitsSearchQuery () {
      this.unitsSearchQuery = ''
    },
    isPersonActiveFilterMatch (filterType, person) {
      if (filterType === 'activePeople') {
        return person.active
      }
      if (filterType === 'inactivePeople') {
        return !person.active
      }
      return true
    },
    confirmAddition () {
      this.$refs.confirm.ask('Sind Sie sicher, dass Sie eine neue WE anlegen wollen?', this.addUnit)
    }
  }
}
</script>

<style scoped>
.scroll-container {
  flex: 1 1 auto;
  flex-wrap: wrap;
  height: calc(100% - 50px);
  overflow-y: auto;
  padding: 5px;
  margin: 10px;
}

.splitpanes.default-theme .splitpanes__pane {
  background-color: #fff;
}

.units-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap: 10px;
}
</style>
