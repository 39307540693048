<template>
  <div class="PersonList">
    <DraggableEntityCardList
      v-if="props.isDraggable"
      :items="items"
      :small="true"
      :new-window="true"
      :icons-position="'bottom'"
      :draggable-change="props.draggableChange"
      :draggable-group="props.draggableGroup"
    >
      <template #empty>
        <slot name="empty">
          <p class="notice">
            Keine Personen bekannt
          </p>
        </slot>
      </template>
    </DraggableEntityCardList>
    <EntityCardList
      v-else
      :placeholder="0"
      :items="items"
      :small="true"
      :new-window="true"
      :icons-position="'bottom'"
    >
      <template #empty>
        <p class="notice">
          Keine Personen bekannt
        </p>
      </template>
    </EntityCardList>
  </div>
</template>
<script setup>
import EntityCardList from '@/components/_partials/Organism/EntityCardList.vue'
import { onMounted, ref, watch } from 'vue'
import { getColor, getIcons, getType } from '@/js/utils/person-list.js'
import DraggableEntityCardList from '@/components/_partials/Organism/DraggableEntityCardList.vue'

const props = defineProps({
  people: {
    type: Array,
    default: () => []
  },
  isDraggable: {
    type: Boolean,
    default: false
  },
  draggableChange: {
    type: Function,
    default: null
  },
  draggableGroup: {
    type: String,
    required: true
  },
  draggableClass: {
    type: String,
    default: ''
  }
})

const items = ref([])

onMounted(() => {
  load()
})

watch(() => props.people, () => {
  load()
}, { deep: true })

function load () {
  const result = []

  for (const person of props.people.map(person => typeof person === 'string' ? JSON.parse(person) : person)) {
    result.push({
      href: '/person/' + person.id,
      color: getColor(person),
      openAccessIcon: person.open_access_partner,
      info: getType(person),
      ...(!person.active && {
        notice: 'Person inaktiv!'
      }),
      icons: getIcons(person),
      label: person.full_name,
      dto: person
    })
  }
  items.value = result
}
</script>
