export const apiPlatformBaseUrl = '/api/v2/'

export function getIdFromIri (iri) {
  if (!iri) {
    return null
  }

  const parts = iri.split('/')
  const lastPart = parts[parts.length - 1]

  if (!isNaN(lastPart) && lastPart.trim() !== '') {
    return parseInt(lastPart, 10)
  }

  return null
}

export function errors (...errors) {
  const result = []

  for (const error of errors) {
    if (!error) {
      continue
    }

    if (error instanceof Error) {
      result.push(error)
      continue
    }

    if (Array.isArray(error.errors)) {
      for (const subError of error.errors) {
        result.push(subError.message)
      }
    }

    if ('message' in error) {
      result.push(error.message)
    }
  }

  return result.flat()
}
